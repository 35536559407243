import React, { forwardRef, useState } from 'react';
import { Button } from '@design-system/button';
import { ExtendableImage } from '@design-system/extendable-image';
import { HeadingBlock } from '@design-system/heading-block';
import { Icon } from '@design-system/icon';
import { PageBlock } from '@design-system/page-block';
import { Typography } from '@design-system/typography';
import { headingBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock';
import { HeadingBlock as HeadingBlockMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock/models';
import { imageMapper } from '@husky-x/gatsby-theme-husky/src/mappers/image';
import { Image as ImageMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/image/models';
import { pageBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock';
import { PageBlock as PageBlockMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock/models';

import { toCamelCase } from './util';

type Ingredient = {
  image: ImageMapperProps;
  heading: HeadingBlockMapperProps;
};

type IngredientsBlockProps = {
  uid: string;
  componentId: string;
  pageBlock: PageBlockMapperProps;
  ingredients: Ingredient[];
  accordionTitle: string;
  accordionDescription: string;
};

export const IngredientsBlock = forwardRef<HTMLDivElement, { modelData: unknown }>((props, ref) => {
  const { uid, componentId, pageBlock, ingredients, accordionTitle, accordionDescription } =
    toCamelCase(props.modelData) as IngredientsBlockProps;

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <PageBlock
      {...pageBlockMapper(pageBlock)}
      className="ingredients-block"
      componentUid={uid}
      ref={ref}
      id={componentId}
    >
      <div className="ingredients-block__content">
        {ingredients?.map((ingredient) => (
          <div className="ingredients-block__tile">
            <ExtendableImage
              className="ingredients-block__tile-image"
              {...imageMapper(ingredient.image)}
            />
            <HeadingBlock
              {...headingBlockMapper(ingredient.heading)}
              className="ingredients-block__tile-heading"
            />
          </div>
        ))}
      </div>
      {accordionTitle && (
        <div className="ingredients-block__accordion">
          <Button
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            className="ingredients-block__accordion-button"
            variant="nav"
            iconPrefix={<Icon icon={isAccordionOpen ? 'hide' : 'add'} />}
            aria-expanded={isAccordionOpen}
            aria-controls="ingredients-description"
          >
            <Typography
              className="ingredients-block__accordion-title"
              content={accordionTitle}
              variant="body"
            />
          </Button>
          <Typography
            className={`ingredients-block__accordion-description ${
              isAccordionOpen ? 'ingredients-block__accordion-description--open' : ''
            }`}
            content={accordionDescription}
            variant="body"
            id="ingredients-description"
          />
        </div>
      )}
    </PageBlock>
  );
});
