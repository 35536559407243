import _ from 'lodash';

export const toCamelCase = (obj) => {
  if (_.isPlainObject(obj)) {
    return _.transform(
      obj as object,
      (result, value, key) => {
        const newKey = _.camelCase(key);
        // eslint-disable-next-line no-param-reassign
        result[newKey] = toCamelCase(value);
      },
      {}
    );
  }

  if (_.isArray(obj)) {
    return obj.map((item) => toCamelCase(item));
  }

  return obj;
};
